import { db, auth } from '../../config/firebaseConfig';
import { collection, query, where, getDocs, addDoc, orderBy, limit, startAfter, documentSnapshot } from 'firebase/firestore';
import { getFacebookPostStats } from '../social-media/FacebookStatsService';
import { getInstagramPostStats } from '../social-media/InstagramStatsService';

const POSTS_PER_PAGE = 5;

export const savePostHistory = async ({
  platform,
  postId,
  pageId,
  timestamp,
  content,
  mediaUrls
}) => {
  try {
    const user = auth.currentUser;
    if (!user) throw new Error("Utilisateur non authentifié");

    const postHistoryRef = collection(db, 'postHistory');
    await addDoc(postHistoryRef, {
      uid: user.uid,
      platform,
      postId,
      pageId,
      timestamp,
      content,
      mediaUrls,
      metrics: {
        likes: 0,
        comments: 0,
        shares: 0
      },
      createdAt: new Date()
    });
  } catch (error) {
    console.error("Erreur lors de la sauvegarde de l'historique:", error);
    throw error;
  }
};

export const getPostHistory = async (lastDocSnapshot = null) => {
  try {
    const user = auth.currentUser;
    if (!user) throw new Error("Utilisateur non authentifié");

    let q;
    if (lastDocSnapshot) {
      q = query(
        collection(db, 'postHistory'),
        where('uid', '==', user.uid),
        orderBy('createdAt', 'desc'),
        startAfter(lastDocSnapshot),
        limit(POSTS_PER_PAGE)
      );
    } else {
      q = query(
        collection(db, 'postHistory'),
        where('uid', '==', user.uid),
        orderBy('createdAt', 'desc'),
        limit(POSTS_PER_PAGE)
      );
    }

    const querySnapshot = await getDocs(q);
    const groupedPosts = {};
    const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];

    // Traitement des posts
    for (const doc of querySnapshot.docs) {
      const postData = doc.data();
      const contentHash = postData.content;

      if (!groupedPosts[contentHash]) {
        groupedPosts[contentHash] = {
          content: postData.content,
          mediaUrls: postData.mediaUrls || [],
          createdAt: postData.timestamp || postData.createdAt,
          facebook: null,
          instagram: null,
          linkedin: null,
          hasDeletedPosts: false
        };
      }

      // Récupérer les statistiques pour chaque plateforme
      if (postData.platform === 'facebook') {
        try {
          const stats = await getFacebookPostStats(postData.postId, postData.pageId);
          groupedPosts[contentHash].facebook = {
            postId: postData.postId,
            pageId: postData.pageId,
            stats: {
              likes: stats.likes,
              comments: stats.comments,
              shares: stats.shares
            },
            postUrl: stats.postUrl,
            isDeleted: stats.isDeleted || false,
            mediaUrls: stats.mediaUrls || postData.mediaUrls || []
          };
          if (stats.isDeleted) {
            groupedPosts[contentHash].hasDeletedPosts = true;
          }
        } catch (error) {
          console.error('Erreur lors de la récupération des stats Facebook:', error);
          groupedPosts[contentHash].facebook = {
            error: error.message,
            isDeleted: true,
            mediaUrls: postData.mediaUrls || []
          };
          groupedPosts[contentHash].hasDeletedPosts = true;
        }
      } else if (postData.platform === 'instagram') {
        try {
          const stats = await getInstagramPostStats(postData.postId, postData.pageId);
          groupedPosts[contentHash].instagram = {
            postId: postData.postId,
            pageId: postData.pageId,
            stats: {
              likes: stats.likes,
              comments: stats.comments
            },
            postUrl: stats.postUrl,
            isDeleted: stats.isDeleted || false,
            mediaUrls: stats.mediaUrls || postData.mediaUrls || [],
            mediaType: stats.mediaType
          };
          if (stats.isDeleted) {
            groupedPosts[contentHash].hasDeletedPosts = true;
          }
        } catch (error) {
          console.error('Erreur lors de la récupération des stats Instagram:', error);
          groupedPosts[contentHash].instagram = {
            error: error.message,
            isDeleted: true,
            mediaUrls: postData.mediaUrls || []
          };
          groupedPosts[contentHash].hasDeletedPosts = true;
        }
      }
    }

    const posts = Object.values(groupedPosts);
    const hasMore = querySnapshot.docs.length === POSTS_PER_PAGE;

    return {
      posts,
      lastDoc: lastVisible,
      hasMore
    };
  } catch (error) {
    console.error("Erreur lors de la récupération de l'historique:", error);
    throw error;
  }
}; 