import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import frLocale from 'date-fns/locale/fr';
import { parseISO } from 'date-fns';
import useMediaQuery from '@mui/material/useMediaQuery';

function ScheduleDatePicker({ selectedDate, onDateChange }) {
  const isMobile = useMediaQuery('(max-width: 600px)'); // Détecte si on est en mobile
  const [openPicker, setOpenPicker] = useState(false); // Contrôle l'ouverture du DateTimePicker

  const handleDateChange = (newDate) => {
    onDateChange(newDate); // Met à jour la date sélectionnée
    if (isMobile && newDate) {
      setOpenPicker(false); // Ferme après une sélection complète
    }
  };

  // Fonction pour s'assurer que la date est un objet Date
  const ensureDateObject = (date) => {
    if (!date) return null;
    return typeof date === 'string' ? parseISO(date) : date;
  };

  const handleOpenPicker = () => {
    setOpenPicker(true); // Ouvre le picker
  };

  const handleClosePicker = () => {
    setOpenPicker(false); // Ferme le picker
  };

  const parsedSelectedDate = ensureDateObject(selectedDate);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={frLocale}>
      {isMobile ? (
        <>
          <IconButton
            color="primary"
            onClick={handleOpenPicker}
            sx={{
              width: 48,
              height: 48,
              borderRadius: 2,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CalendarTodayIcon fontSize="inherit" />
          </IconButton>
          <DateTimePicker
            open={openPicker}
            onClose={handleClosePicker}
            value={parsedSelectedDate}
            onChange={handleDateChange}
            closeOnSelect={false}
            slotProps={{
              actionBar: {
                actions: ['clear', 'accept']
              },
              textField: {
                sx: { display: 'none' }
              }
            }}
          />
        </>
      ) : (
        <DateTimePicker
          open={openPicker}
          onClose={handleClosePicker}
          value={parsedSelectedDate}
          onChange={handleDateChange}
          closeOnSelect={false}
          slotProps={{
            actionBar: {
              actions: ['clear', 'accept']
            },
            textField: {
              fullWidth: true,
              onClick: handleOpenPicker,
              sx: { cursor: 'pointer' },
              InputProps: {
                onClick: handleOpenPicker,
                sx: { cursor: 'pointer' },
                endAdornment: (
                  <IconButton onClick={handleOpenPicker}>
                    <CalendarTodayIcon />
                  </IconButton>
                )
              }
            }
          }}
        />
      )}
    </LocalizationProvider>
  );
}

export default ScheduleDatePicker;
