// src/pages/Dashboard/sections/PostHistory/PostHistoryPage.js
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Container, Grid, CircularProgress, Box, Typography, Button, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../../../../config/firebaseConfig';
import SocialMediaPostCard from '../../../../components/Card/SocialMediaCard/SocialMediaPostCard';
import { getPostHistory } from '../../../../services/firebase/postHistoryService';
import { fetchCompanyInfo } from '../../../../services/firebase/companyService';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const PlatformFilter = ({ selectedPlatforms, onFilterChange }) => (
  <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
    <ToggleButtonGroup
      value={selectedPlatforms}
      onChange={onFilterChange}
      aria-label="platform filter"
      color="primary"
      multiple
    >
      <ToggleButton value="facebook" aria-label="facebook">
        <FacebookIcon sx={{ mr: 1 }} />
        Facebook
      </ToggleButton>
      <ToggleButton value="instagram" aria-label="instagram">
        <InstagramIcon sx={{ mr: 1 }} />
        Instagram
      </ToggleButton>
      <ToggleButton value="linkedin" aria-label="linkedin">
        <LinkedInIcon sx={{ mr: 1 }} />
        LinkedIn
      </ToggleButton>
    </ToggleButtonGroup>
  </Box>
);

function PostHistoryPage() {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [posts, setPosts] = useState([]);
  const [error, setError] = useState(null);
  const [lastDoc, setLastDoc] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [selectedPlatforms, setSelectedPlatforms] = useState(['facebook', 'instagram', 'linkedin']);
  const [companyInfo, setCompanyInfo] = useState({
    name: '',
    logoUrl: ''
  });

  // Ref pour le conteneur de défilement
  const scrollContainerRef = useRef();
  // Ref pour l'élément de chargement
  const loadingRef = useRef();

  const handleFilterChange = (event, newPlatforms) => {
    setSelectedPlatforms(newPlatforms);
  };

  const filterPosts = (posts) => {
    return posts.filter(post => {
      return selectedPlatforms.some(platform => {
        if (platform === 'facebook') return post.facebook && !post.facebook.isDeleted;
        if (platform === 'instagram') return post.instagram && !post.instagram.isDeleted;
        if (platform === 'linkedin') return post.linkedin && !post.linkedin.isDeleted;
        return false;
      });
    });
  };

  const fetchPosts = async (lastDocument = null) => {
    try {
      const result = await getPostHistory(lastDocument);
      return result;
    } catch (error) {
      console.error('Erreur lors de la récupération des posts:', error);
      throw error;
    }
  };

  const loadMorePosts = useCallback(async () => {
    if (!hasMore || isLoadingMore) return;

    setIsLoadingMore(true);
    try {
      const result = await getPostHistory(lastDoc);
      
      if (result.posts.length > 0) {
        setPosts(prevPosts => [...prevPosts, ...result.posts]);
        setLastDoc(result.lastDoc);
        setHasMore(result.hasMore);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error('Erreur lors du chargement:', error);
      setError(error.message);
    } finally {
      setIsLoadingMore(false);
    }
  }, [hasMore, isLoadingMore, lastDoc]);

  // Configuration de l'Intersection Observer
  useEffect(() => {
    if (!scrollContainerRef.current || !loadingRef.current) return;

    const observer = new IntersectionObserver(
      (entries) => {
        const [entry] = entries;
        if (entry.isIntersecting && hasMore && !isLoadingMore) {
          loadMorePosts();
        }
      },
      {
        root: null, // Utilise le viewport comme root
        rootMargin: '100px',
        threshold: 0.1
      }
    );

    observer.observe(loadingRef.current);

    return () => {
      if (loadingRef.current) {
        observer.unobserve(loadingRef.current);
      }
    };
  }, [loadMorePosts, hasMore, isLoadingMore]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          const company = await fetchCompanyInfo(user.uid);
          if (company) {
            setCompanyInfo({
              name: company.name || "Nom de l'entreprise",
              logoUrl: company.logoUrl || ''
            });
          }

          const result = await fetchPosts();
          setPosts(result.posts);
          setLastDoc(result.lastDoc);
          setHasMore(result.hasMore);
        } catch (error) {
          console.error('Erreur lors de la récupération des données:', error);
          setError(error.message);
        } finally {
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
        setError("Vous devez être connecté pour voir l'historique des posts");
      }
    });

    return () => unsubscribe();
  }, []);

  if (isLoading) {
    return (
      <Container maxWidth="md" sx={{ mt: 5, mb: 5, position: 'relative' }}>
        <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '100vh' }}>
          <CircularProgress />
        </Grid>
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="md" sx={{ mt: 5, mb: 5 }}>
        <Typography color="error" align="center">
          {error}
        </Typography>
      </Container>
    );
  }

  const filteredPosts = filterPosts(posts);

  return (
    <Box 
      ref={scrollContainerRef}
      sx={{ 
        display: 'flex', 
        flexDirection: 'column',
        height: 'calc(100vh - 64px)',
        overflowY: 'auto',
        padding: 3,
        scrollBehavior: 'smooth'
      }}
    >
      <Container maxWidth="lg">
        <PlatformFilter
          selectedPlatforms={selectedPlatforms}
          onFilterChange={handleFilterChange}
        />
        <Grid container spacing={3}>
          {filteredPosts.length > 0 ? (
            <>
              {filteredPosts.map((post, index) => (
                <Grid 
                  item 
                  xs={12} 
                  key={`${post.createdAt?.toDate?.()}-${index}`}
                >
                  <SocialMediaPostCard 
                    post={post} 
                    companyName={companyInfo.name}
                    companyLogoUrl={companyInfo.logoUrl}
                  />
                </Grid>
              ))}
            </>
          ) : (
            <Grid item xs={12}>
              <Typography align="center" color="textSecondary">
                Aucune publication trouvée pour les plateformes sélectionnées.
              </Typography>
            </Grid>
          )}

          {/* Élément de chargement */}
          {(hasMore || isLoadingMore) && (
            <Grid 
              item 
              xs={12} 
              ref={loadingRef}
              sx={{ 
                display: 'flex', 
                justifyContent: 'center', 
                py: 4,
                minHeight: '100px'
              }}
            >
              <Box sx={{ textAlign: 'center' }}>
                <CircularProgress size={30} />
                <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                  {isLoadingMore ? 'Chargement en cours...' : 'Chargement...'}
                </Typography>
              </Box>
            </Grid>
          )}
        </Grid>
      </Container>
    </Box>
  );
}

export default PostHistoryPage;
