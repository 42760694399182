import { httpsCallable } from 'firebase/functions';
import { auth, functions } from '../../../config/firebaseConfig';

const publishToInstagramFunction = httpsCallable(functions, 'publishToInstagram');

export const publishToInstagram = async (content, mediaFiles, userId) => {
  try {
    console.log("Début de la publication Instagram");
    console.log("Données à envoyer:", {
      content: content,
      mediaFilesCount: mediaFiles?.length,
      mediaType: mediaFiles?.[0]?.type,
      userId: userId,
    });

    // Vérification de l'authentification
    if (!auth.currentUser) {
      throw new Error("Vous devez être connecté pour publier sur Instagram");
    }

    // Préparation des données des médias
    const preparedMediaFiles = mediaFiles.map(file => ({
      url: file.url,
      type: file.type,
      mimeType: file.type, // Pour la compatibilité avec le back-end
      name: file.name || 'media-file',
    }));

    console.log("Médias préparés:", preparedMediaFiles);

    // Appel de la fonction Cloud
    const result = await publishToInstagramFunction({
      content,
      mediaFiles: preparedMediaFiles,
    });

    console.log("Réponse de la fonction Cloud Instagram:", result.data);

    return {
      success: true,
      id: result.data.id,
    };
  } catch (error) {
    console.error("Erreur détaillée lors de la publication sur Instagram:", {
      message: error.message,
      code: error.code,
      details: error.details,
      response: error.response?.data,
      userId: userId,
    });

    // Gestion des erreurs spécifiques
    if (error.message.includes("Unauthorized") || error.message.includes("non authentifié")) {
      throw new Error("Vous n'êtes pas autorisé à publier. Veuillez vous reconnecter.");
    } else if (error.message.includes("Configuration Instagram")) {
      throw new Error("Votre compte Instagram n'est pas correctement configuré. Veuillez le reconfigurer dans les paramètres.");
    } else if (error.message.includes("média est requis")) {
      throw new Error("Vous devez sélectionner au moins une image ou vidéo à publier.");
    }

    // Si c'est une autre erreur, on la transmet
    throw error;
  }
}; 