import { httpsCallable } from 'firebase/functions';
import { auth, functions } from '../../config/firebaseConfig';
import { saveDraftPost, fetchDraftPosts } from '../firebase/draftPostService';

const generatePostFunction = httpsCallable(functions, 'generatePostOpenAI');
const regeneratePartialPostFunction = httpsCallable(functions, 'regeneratePartialPostOpenAI');

// Fonction pour générer un nouveau post
export const generatePost = async (textContent, mediaFiles, selectedDate) => {
  try {
    // Vérification de l'authentification
    if (!auth.currentUser) {
      throw new Error("Vous devez être connecté pour générer un post");
    }

    // Obtenir le token d'authentification
    const token = await auth.currentUser.getIdToken(true);

    // Appel de la fonction Cloud avec le token
    const result = await generatePostFunction({
      textContent,
      token
    });
    const newContent = result.data.response;

    // Enregistrer le brouillon dans Firebase avec le contenu généré et le prompt utilisé
    const savedPost = await saveDraftPost(newContent, mediaFiles, selectedDate || [], null, textContent, selectedDate);

    // S'assurer que le post a un ID unique
    if (!savedPost.id) {
      throw new Error("Le post sauvegardé n'a pas d'ID");
    }

    return {
      ...savedPost,
      timestamp: new Date(),
      isGenerating: false,
      isPublished: false,
      prompt: textContent,
      mediaUrls: mediaFiles
    };
  } catch (error) {
    console.error('Erreur lors de la génération de la publication :', error);
    throw error;
  }
};

// Fonction pour régénérer un post existant
export const regeneratePost = async (draftId, generatedPosts, setGeneratedPosts) => {
  try {
    // Trouver le post à régénérer
    const post = generatedPosts.find((p) => p.id === draftId);
    if (!post) {
      console.error('Le post à régénérer est introuvable.');
      return;
    }

    // Marquer le post comme étant en cours de génération
    setGeneratedPosts((prevPosts) =>
      prevPosts.map((p) => (p.id === draftId ? { ...p, isGenerating: true } : p))
    );

    // Appel de la fonction Cloud
    const result = await generatePostFunction({
      textContent: post.promptContent || post.content,
      auth: {
        uid: auth.currentUser.uid,
        token: await auth.currentUser.getIdToken()
      }
    });
    const newContent = result.data.response;

    // Mettre à jour le brouillon dans Firebase avec le nouveau contenu généré
    await saveDraftPost(newContent, post.mediaFiles || [], draftId, post.promptContent);

    // Récupérer la liste mise à jour des brouillons depuis Firebase
    const updatedDrafts = await fetchDraftPosts();

    // Mettre à jour l'état avec les brouillons actualisés
    setGeneratedPosts(updatedDrafts);
  } catch (error) {
    console.error('Erreur lors de la régénération de la publication :', error);

    // Réinitialiser l'état de génération en cas d'erreur
    setGeneratedPosts((prevPosts) =>
      prevPosts.map((p) => (p.id === draftId ? { ...p, isGenerating: false } : p))
    );
  }
};

// Fonction pour régénérer une partie d'un post existant
export const regeneratePartialPost = async (fullText, selectedText) => {
  try {
    // Vérification de l'authentification
    if (!auth.currentUser) {
      throw new Error("Vous devez être connecté pour régénérer un post");
    }

    // Obtenir le token d'authentification
    const token = await auth.currentUser.getIdToken(true);

    const result = await regeneratePartialPostFunction({
      fullText,
      selectedText,
      token
    });

    if (!result.data || !result.data.response) {
      throw new Error("La réponse de la fonction est invalide");
    }

    return result.data.response;
  } catch (error) {
    console.error('Erreur lors de la régénération partielle de la publication :', error);
    throw error; // On propage l'erreur pour une meilleure gestion
  }
};
