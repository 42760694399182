import React, { useState, useEffect } from 'react';
import FacebookIcon from '@mui/icons-material/Facebook';
import SocialMediaAuthorizationCard from '../../../../../components/Card/SocialMediaAuthorizationCard';
import { useAuth } from '../../../../../contexts/AuthContext';
import { db } from '../../../../../config/firebaseConfig';
import { doc, setDoc, onSnapshot, getDoc } from 'firebase/firestore';
import { Box, Typography } from '@mui/material';

/* global FB */

const FacebookAuth = () => {
  const { currentUser } = useAuth();
  const [isConnected, setIsConnected] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pageName, setPageName] = useState('');
  const [hasInstagram, setHasInstagram] = useState(false);

  useEffect(() => {
    if (!currentUser?.uid) return;

    const unsubscribe = onSnapshot(
      doc(db, 'socialMediaAccounts', currentUser.uid),
      (doc) => {
        if (doc.exists()) {
          const data = doc.data();
          setIsConnected(data.facebook?.isConnected || false);
          setPageName(data.facebook?.pageName || '');
          setHasInstagram(!!data.instagram?.isConnected);
        }
      }
    );

    return () => unsubscribe();
  }, [currentUser]);

  const handleAuthorizeFacebook = () => {
    setLoading(true);
    try {
      if (typeof FB !== 'undefined') {
        // D'abord, vérifier si on a déjà une page enregistrée
        const docRef = doc(db, 'socialMediaAccounts', currentUser.uid);
        getDoc(docRef).then((docSnapshot) => {
          if (docSnapshot.exists() && docSnapshot.data().facebook?.isConnected) {
            // Si on a déjà une page, vérifier si le token est toujours valide
            FB.api('/me/accounts', { 
              access_token: docSnapshot.data().facebook.userAccessToken,
              fields: 'id,name,access_token,category,tasks'
            }, function(response) {
              if (response && !response.error) {
                console.log('Token Facebook toujours valide');
                setLoading(false);
                return;
              }
              // Si le token n'est plus valide, demander une nouvelle autorisation
              requestNewFacebookAuth();
            });
          } else {
            // Si pas de page enregistrée, demander une nouvelle autorisation
            requestNewFacebookAuth();
          }
        });
      } else {
        console.error('SDK Facebook non chargé.');
        setLoading(false);
      }
    } catch (error) {
      console.error('Erreur lors de l\'autorisation Facebook:', error);
      setLoading(false);
    }
  };

  const requestNewFacebookAuth = () => {
    FB.login(function(response) {
      if (response.authResponse) {
        console.log('Utilisateur connecté avec Facebook.');
        const userAccessToken = response.authResponse.accessToken;

        // Récupérer les pages de l'utilisateur
        FB.api('/me/accounts', {
          access_token: userAccessToken,
          fields: 'id,name,access_token,category,tasks,instagram_business_account'
        }, async function(pageResponse) {
          console.log('Page Response:', pageResponse);

          if (pageResponse && !pageResponse.error && pageResponse.data && pageResponse.data.length > 0) {
            console.log('Pages récupérées:', pageResponse.data);

            const page = pageResponse.data[0];
            const pageId = page.id;
            const pageAccessToken = page.access_token;
            const pageName = page.name;
            const instagramAccount = page.instagram_business_account;

            try {
              const updateData = {
                facebook: {
                  isConnected: true,
                  pageId,
                  pageAccessToken,
                  pageName,
                  connectedAt: new Date(),
                  userAccessToken
                }
              };

              // Si un compte Instagram est lié, on le sauvegarde aussi
              if (instagramAccount) {
                updateData.instagram = {
                  isConnected: true,
                  accountId: instagramAccount.id,
                  connectedAt: new Date(),
                  accessToken: pageAccessToken
                };
              }

              await setDoc(doc(db, 'socialMediaAccounts', currentUser.uid), updateData, { merge: true });

              console.log('Informations sauvegardées:', {
                facebook: true,
                instagram: !!instagramAccount
              });
            } catch (error) {
              console.error('Erreur lors de la sauvegarde:', error);
            }
          }
          setLoading(false);
        });
      } else {
        console.log('L\'utilisateur a annulé la connexion.');
        setLoading(false);
      }
    }, {
      scope: [
        'pages_show_list',
        'pages_read_engagement',
        'pages_manage_posts',
        'pages_manage_engagement',
        'instagram_basic',
        'instagram_content_publish'
      ].join(','),
      auth_type: 'rerequest'
    });
  };

  const handleDisconnect = async () => {
    setLoading(true);
    try {
      await setDoc(doc(db, 'socialMediaAccounts', currentUser.uid), {
        facebook: {
          isConnected: false,
          disconnectedAt: new Date()
        }
      }, { merge: true });

      if (typeof FB !== 'undefined') {
        FB.logout();
      }
    } catch (error) {
      console.error('Erreur lors de la déconnexion:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <SocialMediaAuthorizationCard
      networkName="Facebook"
      networkLogo={<FacebookIcon sx={{ fontSize: 40, color: '#3b5998' }} />}
      description={
        <Box component="div">
          <Typography variant="body2" gutterBottom>
            Connectez votre page Facebook pour publier automatiquement du contenu.
            {isConnected && (
              <Typography variant="caption" display="block" color="success.main">
                Page connectée : {pageName}
                {hasInstagram && " (avec compte Instagram Business)"}
              </Typography>
            )}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Permissions requises :
            <Box component="ul" sx={{ mt: 1, mb: 0 }}>
              <li>Gestion des publications</li>
              <li>Accès aux données de la page</li>
              <li>Gestion des interactions</li>
              {!isConnected && (
                <li>Accès à Instagram Business (optionnel)</li>
              )}
            </Box>
          </Typography>
        </Box>
      }
      linkedAccounts={isConnected && hasInstagram ? ['Instagram Business'] : undefined}
      isConnected={isConnected}
      isLoading={loading}
      onAuthorize={handleAuthorizeFacebook}
      onDisconnect={handleDisconnect}
    />
  );
};

export default FacebookAuth;
