import React, { useState, useEffect, useRef } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { Container, Grid, CircularProgress, Box } from '@mui/material';
import PostCard from '../../../../components/Card/PostCard/PostCard';
import PromptInput from '../../../../components/PromptInput/PromptInput';
import { fetchCompanyInfo } from '../../../../services/firebase/companyService';
import { fetchFacebookPageInfo } from '../../../../services/socialNetworks/facebook/facebookPageService';
import { fetchDraftPosts, deleteDraftPost, fetchDraftPostById } from '../../../../services/firebase/draftPostService';
import { auth } from '../../../../config/firebaseConfig';
import { generatePost, regeneratePost } from '../../../../services/OpenAi/generatePostService';
import { useSearchParams } from 'react-router-dom';
import { publishToInstagram } from '../../../../services/socialNetworks/instagram/instagramPostService';
import { savePostHistory } from '../../../../services/firebase/postHistoryService';
import { publishToFacebookCloud } from '../../../../services/socialNetworks/facebook/facebookCloudService';

function NewPostPage() {
  const [isAuthLoading, setIsAuthLoading] = useState(true);
  const [companyName, setCompanyName] = useState('');
  const [companyLogoUrl, setCompanyLogoUrl] = useState('');
  const [textContent, setTextContent] = useState('');
  const [selectedDate, setSelectedDate] = useState(null);
  const [generatedPosts, setGeneratedPosts] = useState([]);
  const [mediaFiles, setMediaFiles] = useState([]);
  const scrollContainerRef = useRef(null);

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const urlSelectedDate = searchParams.get('selectedDate');
    if (urlSelectedDate) {
      setSelectedDate(new Date(urlSelectedDate));
    }
  }, [searchParams]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const companyData = await fetchCompanyInfo(user.uid);
        if (companyData) {
          setCompanyName(companyData.name || "Nom de l'entreprise");
          setCompanyLogoUrl(companyData.logoUrl || '');
        }
        
        await fetchFacebookPageInfo();

        const fetchPosts = async () => {
          try {
            const fetchedPosts = await fetchDraftPosts();
            const postsWithPlatforms = fetchedPosts
              .map(post => ({
                ...post,
                selectedPlatforms: post.selectedPlatforms || {
                  facebook: false,
                  instagram: false,
                  twitter: false,
                  linkedin: false
                }
              }))
              .sort((a, b) => {
                const dateA = a.timestamp ? a.timestamp.toDate() : new Date(0);
                const dateB = b.timestamp ? b.timestamp.toDate() : new Date(0);
                return dateA - dateB;
              });
            setGeneratedPosts(postsWithPlatforms);
            requestAnimationFrame(() => {
              window.scrollTo(0, document.body.scrollHeight);
            });
          } catch (error) {
            console.error('Erreur lors de la récupération des posts:', error);
          }
        };

        fetchPosts();
      } else {
        console.error('Aucun utilisateur connecté.');
      }
      setIsAuthLoading(false);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (generatedPosts.length > 0) {
      requestAnimationFrame(() => {
        window.scrollTo(0, document.body.scrollHeight);
      });
    }
  }, [generatedPosts.length]);

  // Scroll en bas après le chargement des posts
  useEffect(() => {
    if (!isAuthLoading && generatedPosts.length > 0 && scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop = scrollContainerRef.current.scrollHeight;
    }
  }, [isAuthLoading, generatedPosts]);

  // Scroll en bas quand un nouveau post est ajouté
  useEffect(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop = scrollContainerRef.current.scrollHeight;
    }
  }, [generatedPosts.length]);

  if (isAuthLoading) {
    return (
      <Container maxWidth="md" sx={{ mt: 5, mb: 5, position: 'relative' }}>
        <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '100vh' }}>
          <CircularProgress />
        </Grid>
      </Container>
    );
  }

  const handleGeneratePost = async () => {
    try {
      const tempId = `temp-${Date.now()}`;
      const currentDate = selectedDate instanceof Date ? selectedDate : new Date();
      
      const tempPost = {
        id: tempId,
        content: '',
        mediaFiles: [...mediaFiles],
        selectedDate: currentDate,
        isGenerating: true,
        isPublished: false,
        timestamp: currentDate
      };
      
      setGeneratedPosts(prevPosts => [...prevPosts, tempPost]);

      const newPost = await generatePost(textContent, mediaFiles, currentDate);
      
      if (newPost) {
        // Récupérer le post fraîchement créé depuis la base
        const freshPost = await fetchDraftPostById(newPost.id);
        
        if (freshPost) {
          setGeneratedPosts(prevPosts => {
            const filteredPosts = prevPosts.filter(post => post.id !== tempId);
            return [...filteredPosts, {
              ...freshPost,
              selectedPlatforms: {
                facebook: false,
                instagram: false,
                twitter: false,
                linkedin: false
              }
            }];
          });
        }

        setTextContent('');
        setMediaFiles([]);
        setSelectedDate(null);
      }
    } catch (error) {
      console.error('Erreur lors de la génération du post:', error);
      setGeneratedPosts(prevPosts => prevPosts.filter(post => !post.id.startsWith('temp-')));
    }
  };

  const handleRegeneratePost = async (postId) => {
    await regeneratePost(postId, generatedPosts, setGeneratedPosts);
  };

  const handleDeletePost = async (postIndex) => {
    const post = generatedPosts[postIndex];
    try {
      await deleteDraftPost(post.id, post.mediaUrls || []);
      setGeneratedPosts(prevPosts => prevPosts.filter((_, index) => index !== postIndex));
    } catch (error) {
      console.error('Erreur lors de la suppression du post:', error);
    }
  };

  const handlePublish = async (postIndex) => {
    const post = generatedPosts[postIndex];
    const { selectedPlatforms } = post;

    setGeneratedPosts((prevPosts) =>
      prevPosts.map((p, index) => (index === postIndex ? { ...p, isPublishing: true } : p))
    );

    try {
      const publishResults = [];
      const errors = [];
      const publishTimestamp = new Date().toISOString();

      if (selectedPlatforms.facebook) {
        try {
          const fbResponse = await publishToFacebookCloud(
            post.content,
            post.mediaFiles || post.mediaUrls || [],
            auth.currentUser.uid
          );
          
          if (fbResponse.success) {
            publishResults.push({
              platform: 'facebook',
              postId: fbResponse.id,
              pageId: fbResponse.pageId,
              timestamp: publishTimestamp,
              content: post.content,
              mediaUrls: post.mediaUrls || post.mediaFiles || []
            });
          }
        } catch (error) {
          console.error("Erreur Cloud Function Facebook:", error);
          errors.push({ platform: 'facebook', error });
        }
      }

      if (selectedPlatforms.instagram) {
        try {
          const igResponse = await publishToInstagram(
            post.content, 
            post.mediaFiles || post.mediaUrls || [],
            auth.currentUser.uid
          );
          
          if (igResponse.success) {
            publishResults.push({
              platform: 'instagram',
              postId: igResponse.id,
              pageId: igResponse.id,
              timestamp: publishTimestamp,
              content: post.content,
              mediaUrls: post.mediaUrls || post.mediaFiles || []
            });
          } else {
            throw new Error("La publication Instagram a échoué");
          }
        } catch (error) {
          console.error("Erreur lors de la publication Instagram:", error);
          errors.push({ platform: 'instagram', error });
        }
      }

      if (publishResults.length > 0) {
        await Promise.all(publishResults.map(result => savePostHistory(result)));

        const allSelectedPlatformsSucceeded = Object.entries(selectedPlatforms)
          .filter(([_, isSelected]) => isSelected)
          .every(([platform]) => 
            publishResults.some(result => result.platform === platform)
          );

        if (allSelectedPlatformsSucceeded) {
          await deleteDraftPost(post.id, post.mediaUrls || []);
        }

        setGeneratedPosts((prevPosts) =>
          prevPosts.map((p, index) =>
            index === postIndex ? { ...p, isPublishing: false, isPublished: allSelectedPlatformsSucceeded } : p
          )
        );
      }

      if (errors.length > 0) {
        errors.forEach(({ platform, error }) => {
          console.error(`Erreur lors de la publication sur ${platform}:`, error);
        });
        throw new Error("Certaines publications ont échoué");
      }

    } catch (error) {
      console.error("Erreur lors de la publication :", error);
      setGeneratedPosts((prevPosts) =>
        prevPosts.map((p, index) => 
          index === postIndex ? { ...p, isPublishing: false } : p
        )
      );
    }
  };

  const handleUpdatePost = (draftId, updatedData) => {
    setGeneratedPosts((prevPosts) =>
      prevPosts.map((post) => (post.id === draftId ? { ...post, ...updatedData } : post))
    );
  };

  return (
    <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Box 
        ref={scrollContainerRef}
        sx={{ 
          flex: 1,
          overflowY: 'auto',
          pt: { xs: 2, sm: 5 },
          pb: '200px'
        }}
      >
        <Container maxWidth="md">
          <Grid 
            container 
            spacing={{ xs: 2, sm: 3 }} 
            direction="column"
          >
            {generatedPosts.map((post, index) => (
              <Grid item key={post.id || `post-${index}`}>
                <PostCard
                  prompt={post.prompt}
                  content={post.content}
                  mediaFiles={post.mediaUrls || post.mediaFiles || []}
                  companyName={companyName}
                  companyLogoUrl={companyLogoUrl}
                  timestamp={post.timestamp}
                  selectedDate={post.selectedDate}
                  isGenerating={post.isGenerating}
                  isPublishing={post.isPublishing}
                  isPublished={post.isPublished}
                  onPublishToFacebook={() => handlePublish(index)}
                  onDelete={() => handleDeletePost(index)}
                  onRegenerate={() => handleRegeneratePost(post.id)}
                  draftId={post.id}
                  onUpdate={handleUpdatePost}
                  scroll={false}
                  post={post}
                  userId={auth.currentUser?.uid}
                />
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
      <Box 
        sx={{ 
          position: 'fixed',
          bottom: 0,
          left: { xs: 0, sm: '240px' },
          right: 0,
          zIndex: 1000,
        }}
      >
        <Container 
          maxWidth="md" 
          className="prompt-container"
        >
          <PromptInput
            textContent={textContent}
            setTextContent={setTextContent}
            mediaFiles={mediaFiles}
            setMediaFiles={setMediaFiles}
            generatePost={handleGeneratePost}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
          />
        </Container>
      </Box>
    </Box>
  );
}

export default NewPostPage;
