import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Box, Button, Grid, Avatar, ToggleButton, ToggleButtonGroup, CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import ShareIcon from '@mui/icons-material/Share';
import { getStorage, ref as storageRef, getDownloadURL } from 'firebase/storage';
import { useNavigate } from 'react-router-dom';

const PlatformFilter = ({ onFilterChange, selectedPlatforms }) => (
  <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
    <ToggleButtonGroup
      value={selectedPlatforms}
      onChange={onFilterChange}
      aria-label="platform filter"
      color="primary"
      multiple
    >
      <ToggleButton value="facebook" aria-label="facebook">
        <FacebookIcon sx={{ mr: 1 }} />
        Facebook
      </ToggleButton>
      <ToggleButton value="instagram" aria-label="instagram">
        <InstagramIcon sx={{ mr: 1 }} />
        Instagram
      </ToggleButton>
      <ToggleButton value="linkedin" aria-label="linkedin">
        <LinkedInIcon sx={{ mr: 1 }} />
        LinkedIn
      </ToggleButton>
    </ToggleButtonGroup>
  </Box>
);

const ProfileHeader = ({ companyName, companyLogoUrl, platforms }) => (
  <Box sx={{ 
    display: 'flex', 
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 2, 
    mb: 2,
    pb: 2,
    borderBottom: '1px solid',
    borderColor: 'divider'
  }}>
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
      <Avatar 
        src={companyLogoUrl} 
        alt={companyName}
        sx={{ width: 48, height: 48 }}
      />
      <Typography variant="h6">{companyName}</Typography>
    </Box>
    <Box sx={{ display: 'flex', gap: 1 }}>
      {platforms.facebook && (
        <FacebookIcon color="primary" />
      )}
      {platforms.instagram && (
        <InstagramIcon color="primary" />
      )}
      {platforms.linkedin && (
        <LinkedInIcon color="primary" />
      )}
    </Box>
  </Box>
);

const PlatformStats = ({ platform, icon, stats, postUrl, disabled }) => (
  <Box sx={{ 
    display: 'flex', 
    alignItems: 'center', 
    gap: 2, 
    py: 1,
    opacity: disabled ? 0.5 : 1 
  }}>
    <Box sx={{ display: 'flex', alignItems: 'center', width: 120 }}>
      {icon}
      <Typography sx={{ ml: 1 }}>{platform}</Typography>
    </Box>
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 3, flex: 1 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <ThumbUpIcon fontSize="small" />
        <Typography>{stats.likes || 0}</Typography>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <ChatBubbleIcon fontSize="small" />
        <Typography>{stats.comments || 0}</Typography>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <ShareIcon fontSize="small" />
        <Typography>{stats.shares || 0}</Typography>
      </Box>
    </Box>
    <Button 
      variant="outlined" 
      disabled={disabled}
      onClick={() => window.open(postUrl, '_blank')}
    >
      Voir le post
    </Button>
  </Box>
);

const TotalStats = ({ stats }) => (
  <Box sx={{ 
    display: 'flex', 
    alignItems: 'center', 
    gap: 2, 
    py: 1,
    borderTop: '2px solid',
    borderColor: 'divider',
    mt: 1
  }}>
    <Box sx={{ display: 'flex', alignItems: 'center', width: 120 }}>
      <Typography fontWeight="bold">TOTAL</Typography>
    </Box>
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 3, flex: 1 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <ThumbUpIcon fontSize="small" />
        <Typography fontWeight="bold">{stats.totalLikes}</Typography>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <ChatBubbleIcon fontSize="small" />
        <Typography fontWeight="bold">{stats.totalComments}</Typography>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <ShareIcon fontSize="small" />
        <Typography fontWeight="bold">{stats.totalShares}</Typography>
      </Box>
    </Box>
  </Box>
);

const MediaPreview = ({ post }) => {
  const [failedUrls, setFailedUrls] = useState(new Set());
  const [validatedUrls, setValidatedUrls] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // Fonction pour vérifier si une URL est valide
  const isValidUrl = (url) => {
    if (!url) return false;
    try {
      new URL(url);
      return true;
    } catch {
      return false;
    }
  };

  // Récupérer tous les médias disponibles
  const getAllMediaUrls = () => {
    const seenUrls = new Set();
    const result = [];
    
    const addUniqueMedia = (media) => {
      if (!media) return;
      const url = typeof media === 'string' ? media : media.url;
      
      if (!url || !isValidUrl(url) || failedUrls.has(url)) {
        return;
      }
      
      // Ne pas ajouter les URLs de Firebase Storage
      if (url.includes('firebasestorage.googleapis.com')) {
        return;
      }
      
      if (!seenUrls.has(url)) {
        seenUrls.add(url);
        result.push(typeof media === 'string' ? { url: media } : media);
      }
    };

    // Priorité aux médias des APIs
    if (post.facebook?.mediaUrls && Array.isArray(post.facebook.mediaUrls)) {
      post.facebook.mediaUrls.forEach(addUniqueMedia);
    }
    
    if (post.instagram?.mediaUrls && Array.isArray(post.instagram.mediaUrls)) {
      post.instagram.mediaUrls.forEach(addUniqueMedia);
    }

    return result;
  };

  useEffect(() => {
    setIsLoading(true);
    const urls = getAllMediaUrls();
    setValidatedUrls(urls);
    setIsLoading(false);
  }, [post, failedUrls]);

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!validatedUrls.length) {
    return null;
  }

  const handleImageError = (url) => {
    setFailedUrls(prev => new Set([...prev, url]));
  };

  return (
    <Grid container spacing={1} sx={{ mt: 2 }}>
      {validatedUrls.map((media, index) => {
        if (!media.url || failedUrls.has(media.url)) return null;

        const isVideo = 
          (media.type && (
            media.type.toLowerCase().includes('video') || 
            media.type === 'VIDEO' || 
            media.type === 'REELS'
          )) || 
          (media.url && media.url.toLowerCase().match(/\.(mp4|mov|avi)$/));

        return (
          <Grid 
            item 
            xs={12} 
            sm={validatedUrls.length > 1 ? 6 : 12} 
            md={validatedUrls.length > 2 ? 4 : (validatedUrls.length > 1 ? 6 : 12)} 
            key={`${media.url}-${index}`}
          >
            {isVideo ? (
              <Box sx={{ 
                position: 'relative', 
                paddingTop: '56.25%',
                width: '100%',
                borderRadius: 1,
                overflow: 'hidden',
                bgcolor: 'background.default'
              }}>
                <video 
                  controls 
                  src={media.url}
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover'
                  }}
                  onError={() => handleImageError(media.url)}
                />
              </Box>
            ) : (
              <Box sx={{ 
                position: 'relative', 
                paddingTop: '100%',
                width: '100%',
                borderRadius: 1,
                overflow: 'hidden',
                bgcolor: 'background.default'
              }}>
                <img 
                  src={media.url}
                  alt={`Media ${index + 1}`}
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover'
                  }}
                  onError={() => handleImageError(media.url)}
                />
              </Box>
            )}
          </Grid>
        );
      })}
    </Grid>
  );
};

const TokenExpirationDialog = ({ open, platform, onClose }) => {
  const navigate = useNavigate();

  const handleGoToSettings = () => {
    navigate('/settings');
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Reconnexion nécessaire</DialogTitle>
      <DialogContent>
        <Typography>
          Votre session {platform} a expiré. Vous devez vous reconnecter pour voir les statistiques et les médias de vos publications.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Fermer</Button>
        <Button onClick={handleGoToSettings} variant="contained" color="primary">
          Aller aux paramètres
        </Button>
      </DialogActions>
    </Dialog>
  );
};

function SocialMediaPostCard({ post, companyName, companyLogoUrl }) {
  const [tokenDialogOpen, setTokenDialogOpen] = useState(false);
  const [expiredPlatform, setExpiredPlatform] = useState(null);

  const {
    facebook,
    instagram,
    linkedin,
  } = post;

  useEffect(() => {
    // Vérifier les erreurs de token
    if (facebook?.error?.includes('token') || facebook?.tokenExpired) {
      setExpiredPlatform('Facebook');
      setTokenDialogOpen(true);
    } else if (instagram?.error?.includes('token') || instagram?.tokenExpired) {
      setExpiredPlatform('Instagram');
      setTokenDialogOpen(true);
    }
  }, [facebook, instagram]);

  // Déterminer les plateformes actives en vérifiant aussi les erreurs de token
  const activePlatforms = {
    facebook: !!facebook && !facebook.isDeleted && !facebook.tokenExpired && !facebook.error?.includes('token'),
    instagram: !!instagram && !instagram.isDeleted && !instagram.tokenExpired && !instagram.error?.includes('token'),
    linkedin: !!linkedin && !linkedin.isDeleted
  };

  const totalStats = {
    totalLikes: (facebook?.stats?.likes || 0) + (instagram?.stats?.likes || 0) + (linkedin?.stats?.likes || 0),
    totalComments: (facebook?.stats?.comments || 0) + (instagram?.stats?.comments || 0) + (linkedin?.stats?.comments || 0),
    totalShares: (facebook?.stats?.shares || 0) + (instagram?.stats?.shares || 0) + (linkedin?.stats?.shares || 0)
  };

  return (
    <Card sx={{ width: '100%' }}>
      <CardContent>
        <ProfileHeader 
          companyName={companyName} 
          companyLogoUrl={companyLogoUrl} 
          platforms={activePlatforms}
        />
        
        {/* Afficher la date et le contenu de l'API si disponible, sinon utiliser les données Firestore */}
        <Typography variant="body2" color="text.secondary" gutterBottom>
          {(facebook?.timestamp || instagram?.timestamp || post.createdAt) && 
            new Date(facebook?.timestamp || instagram?.timestamp || post.createdAt).toLocaleString('fr-FR')}
        </Typography>
        
        <Typography variant="body1" sx={{ whiteSpace: 'pre-line', mb: 2 }}>
          {facebook?.description || instagram?.caption || post.content}
        </Typography>

        <MediaPreview post={post} />

        <Box sx={{ mt: 3 }}>
          {activePlatforms.facebook ? (
            <PlatformStats
              platform="Facebook"
              icon={<FacebookIcon color="primary" />}
              stats={facebook?.stats || {}}
              postUrl={facebook?.postUrl}
              disabled={false}
            />
          ) : facebook?.error && (
            <Typography color="error" sx={{ mb: 2 }}>
              {facebook.error}
            </Typography>
          )}

          {activePlatforms.instagram ? (
            <PlatformStats
              platform="Instagram"
              icon={<InstagramIcon color="primary" />}
              stats={instagram?.stats || {}}
              postUrl={instagram?.postUrl}
              disabled={false}
            />
          ) : instagram?.error && (
            <Typography color="error" sx={{ mb: 2 }}>
              {instagram.error}
            </Typography>
          )}

          {activePlatforms.linkedin && (
            <PlatformStats
              platform="LinkedIn"
              icon={<LinkedInIcon color="primary" />}
              stats={linkedin?.stats || {}}
              postUrl={linkedin?.postUrl}
              disabled={false}
            />
          )}
          
          {(activePlatforms.facebook || activePlatforms.instagram || activePlatforms.linkedin) && (
            <TotalStats stats={totalStats} />
          )}
        </Box>

        <TokenExpirationDialog 
          open={tokenDialogOpen} 
          platform={expiredPlatform}
          onClose={() => setTokenDialogOpen(false)}
        />
      </CardContent>
    </Card>
  );
}

export default SocialMediaPostCard; 