import { db, storage, auth } from '../../config/firebaseConfig';
import { collection, addDoc, getDocs, deleteDoc, doc, query, where, updateDoc, getDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import axios from 'axios';

export const saveDraftPost = async (content, mediaFiles, selectedDate, draftId = null, promptContent = '') => {
  try {
    const user = auth.currentUser;
    if (!user) throw new Error("Utilisateur non authentifié");

    const mediaUrls = [];
    for (let file of mediaFiles) {
      const uniqueFileName = `${Date.now()}_${user.uid}_${file.name}`;
      const mediaRef = ref(storage, `drafts/${user.uid}/${uniqueFileName}`);
      await uploadBytes(mediaRef, file);
      const downloadURL = await getDownloadURL(mediaRef);
      mediaUrls.push({ url: downloadURL, type: file.type, name: uniqueFileName });
    }

    const postData = {
      uid: user.uid,
      content,
      mediaUrls,
      selectedDate,
      promptContent,
      isPublished: false,
      timestamp: new Date(),
    };

    if (draftId) {
      // Mettre à jour un brouillon existant
      const draftDocRef = doc(db, 'draftPosts', draftId);
      await updateDoc(draftDocRef, postData);
      return {
        id: draftId,
        ...postData
      };
    } else {
      // Créer un nouveau brouillon
      const docRef = await addDoc(collection(db, 'draftPosts'), postData);
      return {
        id: docRef.id,
        ...postData
      };
    }
  } catch (error) {
    console.error("Erreur lors de l'enregistrement du brouillon :", error);
    throw error;
  }
};

// Fonction pour récupérer tous les brouillons de l'utilisateur authentifié
export const fetchDraftPosts = async () => {
  try {
    const user = auth.currentUser;
    if (!user) throw new Error("Utilisateur non authentifié");

    const draftsQuery = query(
      collection(db, 'draftPosts'),
      where('uid', '==', user.uid)
    );

    const draftsQuerySnapshot = await getDocs(draftsQuery);
    return draftsQuerySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      selectedPlatforms: doc.data().selectedPlatforms || {
        facebook: false,
        instagram: false,
        twitter: false,
        linkedin: false
      }
    }));
  } catch (error) {
    console.error("Erreur:", error);
    throw error;
  }
};

// Fonction pour télécharger une image depuis Firebase Storage
export const downloadImageBlob = async (imagePath) => {
  try {
    const imageRef = ref(storage, imagePath);
    const url = await getDownloadURL(imageRef);
    const response = await axios.get(url, {
      responseType: 'blob',
    });
    return response.data; // Ceci est le Blob de l'image
  } catch (error) {
    console.error("Erreur lors du téléchargement de l'image :", error);
    throw error;
  }
};

// Fonction pour récupérer un brouillon spécifique
export const getDraftPost = async (draftId) => {
  try {
    const draftDocRef = doc(db, 'draftPosts', draftId);
    const draftDoc = await getDoc(draftDocRef);
    if (!draftDoc.exists()) {
      throw new Error("Le brouillon spécifié est introuvable.");
    }
    return { id: draftDoc.id, ...draftDoc.data() };
  } catch (error) {
    console.error("Erreur lors de la récupération du brouillon :", error);
    throw error;
  }
};

// Fonction updateDraftPost dans draftPostService.js
export const updateDraftPost = async (draftId, updatedData) => {
  try {
    if (!draftId) {
      throw new Error("draftId est indéfini. Impossible de mettre à jour le brouillon.");
    }

    // Récupérer le brouillon actuel pour déterminer les différences
    const existingDraft = await getDraftPost(draftId);

    const existingMediaUrls = existingDraft.mediaUrls || [];
    const updatedMediaUrls = updatedData.mediaUrls || [];

    // Déterminer les médias à supprimer
    const removedMedia = existingMediaUrls.filter(
      (existingMedia) => !updatedMediaUrls.some((updatedMedia) => updatedMedia.url === existingMedia.url)
    );

    // Supprimer les médias du stockage Firebase avant de mettre à jour le brouillon
    if (removedMedia.length > 0) {
      for (const media of removedMedia) {
        await deleteMediaFile(media);
      }
    }

    const draftDocRef = doc(db, 'draftPosts', draftId);
    await updateDoc(draftDocRef, updatedData);
  } catch (error) {
    console.error("Erreur lors de la mise à jour du brouillon :", error);
    throw error;
  }
};

export const deleteDraftPost = async (draftId, mediaUrls) => {
  try {
    if (Array.isArray(mediaUrls)) {
      for (const media of mediaUrls) {
        await deleteMediaFile(media);
      }
    }
    await deleteDoc(doc(db, 'draftPosts', draftId));
  } catch (error) {
    console.error("Erreur lors de la suppression du brouillon :", error);
    throw error;
  }
};

// Fonction pour uploader des fichiers médias sur Firebase Storage
export const uploadMediaFiles = async (mediaFiles) => {
  try {
    const user = auth.currentUser;
    if (!user) throw new Error("Utilisateur non authentifié");

    const mediaUrls = [];

    for (let file of mediaFiles) {
      const uniqueFileName = `${Date.now()}_${user.uid}_${file.name}`;
      const mediaRef = ref(storage, `drafts/${user.uid}/${uniqueFileName}`);
      await uploadBytes(mediaRef, file);
      const downloadURL = await getDownloadURL(mediaRef);
      mediaUrls.push({ url: downloadURL, type: file.type, name: uniqueFileName });
    }

    return mediaUrls;
  } catch (error) {
    console.error("Erreur lors de l'upload des fichiers médias :", error);
    throw error;
  }
};

// Fonction commune pour supprimer un fichier média de Firebase Storage
export const deleteMediaFile = async (media) => {
  try {
    if (!media.url) {
      throw new Error("URL du média manquante pour la suppression.");
    }
    const storagePath = decodeURIComponent(new URL(media.url).pathname.split('/o/')[1]);
    const mediaRef = ref(storage, storagePath);
    await deleteObject(mediaRef);
  } catch (error) {
    if (error.code === 'storage/object-not-found') {
      // L'objet n'existe pas, on peut ignorer cette erreur
      console.warn(`Le média n'existe pas dans Firebase Storage : ${media.url}`);
    } else {
      console.error("Erreur lors de la suppression du média de Firebase Storage :", error);
      throw error;
    }
  }
};

export const fetchDraftPostsByDateRange = async (startDate, endDate) => {
  try {
    const user = auth.currentUser;
    if (!user) throw new Error("Utilisateur non authentifié");

    const draftsQuery = query(
      collection(db, 'draftPosts'),
      where('uid', '==', user.uid),
      where('isPublished', '==', false),
      where('selectedDate', '>=', startDate),
      where('selectedDate', '<=', endDate)
    );

    const draftsQuerySnapshot = await getDocs(draftsQuery);
    const drafts = [];

    for (const doc of draftsQuerySnapshot.docs) {
      const draftData = doc.data();
      drafts.push({ id: doc.id, ...draftData });
    }

    return drafts.sort((a, b) => new Date(a.selectedDate) - new Date(b.selectedDate));
  } catch (error) {
    console.error("Erreur lors de la récupération des brouillons par intervalle de dates :", error);
    throw error;
  }
};

export const fetchDraftPostById = async (postId) => {
  try {
    const postRef = doc(db, 'draftPosts', postId);
    const postSnap = await getDoc(postRef);
    
    if (postSnap.exists()) {
      return {
        id: postSnap.id,
        ...postSnap.data()
      };
    }
    return null;
  } catch (error) {
    console.error('Erreur lors de la récupération du post:', error);
    throw error;
  }
};
