// MediaPreview.js
import React, { useState } from 'react';
import { Box, Dialog, DialogContent, IconButton, Button, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import 'react-image-lightbox/style.css';
import { storage } from '../../../config/firebaseConfig';

function MediaPreview({ mediaFiles, isEditing, onRemoveMedia, onAddMedia }) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogIndex, setDialogIndex] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleMediaClick = (index) => {
    setDialogIndex(index);
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleNextMedia = () => {
    setDialogIndex((dialogIndex + 1) % mediaFiles.length);
  };

  const handlePrevMedia = () => {
    setDialogIndex((dialogIndex + mediaFiles.length - 1) % mediaFiles.length);
  };

  const handleAddMedia = (event) => {
    const newFiles = Array.from(event.target.files);
    onAddMedia(newFiles);
  };

  return (
    <>
      <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap', mt: 2 }}>
        {mediaFiles && Array.isArray(mediaFiles) && mediaFiles.length > 0 && (
          mediaFiles.map((file, index) => {
            const mediaUrl = file?.url || URL.createObjectURL(file);

            return (
              <Box key={index} sx={{ position: 'relative', width: isMobile ? 100 : 150, height: isMobile ? 100 : 150, flexShrink: 0 }}>
                {file?.type?.startsWith("image") ? (
                  <img
                    src={mediaUrl}
                    alt={`media-${index}`}
                    style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: 8, cursor: 'pointer' }}
                    onClick={() => handleMediaClick(index)}
                  />
                ) : (
                  <video
                    src={mediaUrl}
                    controls
                    style={{ width: '100%', height: '100%', borderRadius: 8, cursor: 'pointer' }}
                    onClick={() => handleMediaClick(index)}
                  />
                )}
                {isEditing && (
                  <IconButton
                    onClick={() => onRemoveMedia(index)}
                    sx={{ 
                      position: 'absolute', 
                      top: -8, 
                      right: -8, 
                      backgroundColor: 'rgba(255, 255, 255, 0.7)', 
                      boxShadow: 2,
                      transform: 'translate(25%, -25%)',
                      border: '1px solid #ccc'
                    }}
                  >
                    <CloseIcon sx={{ color: '#d32f2f' }} />
                  </IconButton>
                )}
              </Box>
            );
          })
        )}
      </Box>

      {/* Bouton pour ajouter un média en mode édition */}
      {isEditing && (
        <Box sx={{ mt: 2, textAlign: 'center' }}>
          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            component="label"
          >
            Ajouter un média
            <input
              type="file"
              accept="image/*,video/*"
              multiple
              hidden
              onChange={handleAddMedia}
            />
          </Button>
        </Box>
      )}

      {/* Dialog pour l'affichage des médias en grand */}
      {mediaFiles[dialogIndex] && (
        <Dialog open={isDialogOpen} onClose={handleCloseDialog} maxWidth="md" fullWidth>
          <DialogContent sx={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#000' }}>
            <IconButton
              onClick={handleCloseDialog}
              sx={{ position: 'absolute', top: 16, right: 16, color: '#fff' }}
            >
              <CloseIcon />
            </IconButton>
            {mediaFiles[dialogIndex]?.type?.startsWith("image") ? (
              <img
                src={mediaFiles[dialogIndex]?.url || URL.createObjectURL(mediaFiles[dialogIndex])}
                alt={`dialog-media-${dialogIndex}`}
                style={{ maxWidth: '100%', maxHeight: '80vh', borderRadius: 8 }}
              />
            ) : (
              <video
                src={mediaFiles[dialogIndex]?.url || URL.createObjectURL(mediaFiles[dialogIndex])}
                controls
                style={{ maxWidth: '100%', maxHeight: '80vh', borderRadius: 8 }}
              />
            )}
            <Box sx={{ position: 'absolute', bottom: 16, display: 'flex', justifyContent: 'space-between', width: '100%' }}>
              <IconButton onClick={handlePrevMedia} sx={{ color: '#fff' }}>
                ❮
              </IconButton>
              <IconButton onClick={handleNextMedia} sx={{ color: '#fff' }}>
                ❯
              </IconButton>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}

export default MediaPreview;
