import axios from 'axios';
import { db } from '../../config/firebaseConfig';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { auth } from '../../config/firebaseConfig';

const IG_API_VERSION = 'v18.0';

const isTokenExpired = (error) => {
  return (
    error.response?.data?.error?.code === 190 || // Token expiré
    error.response?.data?.error?.type === 'OAuthException' ||
    error.response?.data?.error?.message?.toLowerCase().includes('access token') ||
    error.response?.data?.error?.message?.toLowerCase().includes('session has expired')
  );
};

const handleTokenExpiration = async (userId) => {
  try {
    // Mettre à jour le document pour indiquer que le token est expiré
    const userDocRef = doc(db, 'socialMediaAccounts', userId);
    await updateDoc(userDocRef, {
      'instagram.tokenExpired': true,
      'instagram.accessToken': null
    });

    // Rediriger vers la page de paramètres ou afficher une notification
    window.dispatchEvent(new CustomEvent('tokenExpired', {
      detail: {
        platform: 'instagram',
        message: 'Votre session Instagram a expiré. Veuillez vous reconnecter.'
      }
    }));
  } catch (error) {
    console.error('Erreur lors de la gestion de l\'expiration du token:', error);
  }
};

export const getInstagramPostStats = async (mediaId, accountId) => {
  try {
    // Vérification de l'authentification
    if (!auth.currentUser) {
      return {
        likes: 0,
        comments: 0,
        postUrl: `https://instagram.com/p/${mediaId}`,
        isDeleted: false,
        error: 'Utilisateur non authentifié',
        mediaType: null,
        mediaUrl: null
      };
    }

    // Récupérer le token d'accès Instagram depuis Firestore
    const userDoc = await getDoc(doc(db, 'socialMediaAccounts', auth.currentUser.uid));
    
    if (!userDoc.exists()) {
      return {
        likes: 0,
        comments: 0,
        postUrl: `https://instagram.com/p/${mediaId}`,
        isDeleted: false,
        error: 'Configuration Instagram non trouvée',
        mediaType: null,
        mediaUrl: null
      };
    }

    const instagramData = userDoc.data()?.instagram;
    
    if (!instagramData) {
      return {
        likes: 0,
        comments: 0,
        postUrl: `https://instagram.com/p/${mediaId}`,
        isDeleted: false,
        error: 'Configuration Instagram non trouvée',
        mediaType: null,
        mediaUrl: null
      };
    }

    if (!instagramData.accessToken) {
      return {
        likes: 0,
        comments: 0,
        postUrl: `https://instagram.com/p/${mediaId}`,
        isDeleted: false,
        error: 'Token Instagram non trouvé. Veuillez vous reconnecter à Instagram.',
        mediaType: null,
        mediaUrl: null
      };
    }

    // Si nous avons un token valide mais que tokenExpired est true, on réinitialise l'état
    if (instagramData.tokenExpired && instagramData.accessToken) {
      const userDocRef = doc(db, 'socialMediaAccounts', auth.currentUser.uid);
      await updateDoc(userDocRef, {
        'instagram.tokenExpired': false
      });
    }

    // Vérifier si l'ID du média est valide
    if (!mediaId) {
      throw new Error('ID du média Instagram manquant');
    }

    // Construction de l'URL avec l'ID du média
    const url = `https://graph.facebook.com/${IG_API_VERSION}/${mediaId}`;

    // Récupérer les statistiques du post via l'API Instagram
    const response = await axios.get(url, {
      params: {
        access_token: instagramData.accessToken,
        fields: 'like_count,comments_count,permalink,media_type,media_url,thumbnail_url,children{media_type,media_url,thumbnail_url}'
      }
    });

    const { like_count, comments_count, permalink, media_type, media_url, thumbnail_url, children } = response.data;

    // Extraction des URLs des médias
    let mediaUrls = [];
    
    // Gestion des carrousels
    if (media_type === 'CAROUSEL_ALBUM' && children && children.data) {
      children.data.forEach(child => {
        mediaUrls.push({
          url: child.media_url || child.thumbnail_url,
          type: child.media_type
        });
      });
    } else {
      // Gestion des posts simples (photo ou vidéo)
      mediaUrls.push({
        url: media_url || thumbnail_url,
        type: media_type
      });
    }

    return {
      likes: like_count || 0,
      comments: comments_count || 0,
      postUrl: permalink || `https://instagram.com/p/${mediaId}`,
      mediaUrls,
      mediaType: media_type,
      isDeleted: false
    };
  } catch (error) {
    console.error('Erreur détaillée Instagram:', {
      message: error.message,
      response: error.response?.data,
      status: error.response?.status,
      mediaId,
      accountId
    });

    // Vérifier si le token est expiré
    if (isTokenExpired(error)) {
      await handleTokenExpiration(auth.currentUser.uid);
      return {
        likes: 0,
        comments: 0,
        postUrl: `https://instagram.com/p/${mediaId}`,
        isDeleted: false,
        error: 'Session Instagram expirée. Veuillez vous reconnecter.',
        tokenExpired: true,
        mediaType: null,
        mediaUrl: null
      };
    }

    // Vérifier si le post a été supprimé ou est inaccessible
    const isDeleted = error.response?.status === 400 || 
                     error.response?.data?.error?.code === 100 ||
                     error.response?.data?.error?.type === 'GraphMethodException';

    // Message d'erreur personnalisé
    let errorMessage = 'Erreur lors de la récupération des statistiques';
    if (isDeleted) {
      errorMessage = 'Ce post Instagram n\'est plus disponible ou a été supprimé';
    } else if (error.response?.data?.error?.message) {
      errorMessage = error.response.data.error.message;
    }

    // En cas d'erreur, retourner des statistiques par défaut
    return {
      likes: 0,
      comments: 0,
      postUrl: `https://instagram.com/p/${mediaId}`,
      isDeleted,
      error: errorMessage,
      mediaType: null,
      mediaUrl: null
    };
  }
}; 