import React from 'react';
import { Card, Typography, Box, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';

const SocialMediaAuthorizationCard = ({
  networkName,
  networkLogo,
  description,
  isConnected,
  isLoading,
  onAuthorize,
  onDisconnect,
  linkedAccounts
}) => {
  return (
    <Card sx={{ 
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      p: 3,
      mb: 2,
      minHeight: '250px'  // Hauteur minimale fixe
    }}>
      {/* En-tête avec logo et nom */}
      <Stack direction="row" spacing={2} alignItems="center" mb={2}>
        {networkLogo}
        <Typography variant="h6" component="div">
          {networkName}
          {linkedAccounts && (
            <Typography variant="caption" color="text.secondary" sx={{ ml: 1 }}>
              (Lié à : {linkedAccounts.join(', ')})
            </Typography>
          )}
        </Typography>
      </Stack>

      {/* Description */}
      <Box sx={{ flex: 1 }}>  {/* Prend l'espace restant */}
        {description}
      </Box>

      {/* Bouton en bas */}
      <Box sx={{ mt: 2 }}>
        <LoadingButton
          variant={isConnected ? "outlined" : "contained"}
          color={isConnected ? "error" : "primary"}
          onClick={isConnected ? onDisconnect : onAuthorize}
          loading={isLoading}
          fullWidth
        >
          {isConnected ? "Déconnecter" : "Connecter"}
        </LoadingButton>
      </Box>
    </Card>
  );
};

export default SocialMediaAuthorizationCard;
