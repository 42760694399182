import axios from 'axios';
import { db } from '../../config/firebaseConfig';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { auth } from '../../config/firebaseConfig';

const FB_API_VERSION = 'v18.0';

const isTokenExpired = (error) => {
  return (
    error.response?.data?.error?.code === 190 || // Token expiré
    error.response?.data?.error?.type === 'OAuthException' ||
    error.response?.data?.error?.message?.toLowerCase().includes('access token') ||
    error.response?.data?.error?.message?.toLowerCase().includes('session has expired')
  );
};

const handleTokenExpiration = async (userId) => {
  try {
    // Mettre à jour le document pour indiquer que le token est expiré
    const userDocRef = doc(db, 'socialMediaAccounts', userId);
    await updateDoc(userDocRef, {
      'facebook.tokenExpired': true,
      'facebook.pageAccessToken': null
    });

    // Rediriger vers la page de paramètres ou afficher une notification
    window.dispatchEvent(new CustomEvent('tokenExpired', {
      detail: {
        platform: 'facebook',
        message: 'Votre session Facebook a expiré. Veuillez vous reconnecter.'
      }
    }));
  } catch (error) {
    console.error('Erreur lors de la gestion de l\'expiration du token:', error);
  }
};

export const getFacebookPostStats = async (postId, pageId) => {
  try {
    // Vérification de l'authentification
    if (!auth.currentUser) {
      return {
        likes: 0,
        comments: 0,
        shares: 0,
        postUrl: `https://facebook.com/${pageId}/posts/${postId}`,
        mediaUrls: [],
        isDeleted: false,
        error: 'Utilisateur non authentifié'
      };
    }

    // Récupérer le token d'accès de la page depuis Firestore
    const userDoc = await getDoc(doc(db, 'socialMediaAccounts', auth.currentUser.uid));
    
    if (!userDoc.exists()) {
      return {
        likes: 0,
        comments: 0,
        shares: 0,
        postUrl: `https://facebook.com/${pageId}/posts/${postId}`,
        mediaUrls: [],
        isDeleted: false,
        error: 'Configuration Facebook non trouvée'
      };
    }

    const facebookData = userDoc.data()?.facebook;
    
    if (!facebookData) {
      return {
        likes: 0,
        comments: 0,
        shares: 0,
        postUrl: `https://facebook.com/${pageId}/posts/${postId}`,
        mediaUrls: [],
        isDeleted: false,
        error: 'Configuration Facebook non trouvée'
      };
    }

    if (!facebookData.pageAccessToken) {
      return {
        likes: 0,
        comments: 0,
        shares: 0,
        postUrl: `https://facebook.com/${pageId}/posts/${postId}`,
        mediaUrls: [],
        isDeleted: false,
        error: 'Token Facebook non trouvé. Veuillez vous reconnecter à Facebook.'
      };
    }

    // Si nous avons un token valide mais que tokenExpired est true, on réinitialise l'état
    if (facebookData.tokenExpired && facebookData.pageAccessToken) {
      const userDocRef = doc(db, 'socialMediaAccounts', auth.currentUser.uid);
      await updateDoc(userDocRef, {
        'facebook.tokenExpired': false
      });
    }

    // Vérifier si les IDs sont valides
    if (!postId || !pageId) {
      throw new Error('ID du post ou de la page Facebook manquant');
    }

    // Nettoyage des IDs
    const cleanPostId = postId.includes('_') ? postId : `${pageId}_${postId}`;

    // Construction de l'URL avec le pageId
    const url = `https://graph.facebook.com/${FB_API_VERSION}/${cleanPostId}`;

    // Récupérer les statistiques du post via l'API Facebook
    const response = await axios.get(url, {
      params: {
        access_token: facebookData.pageAccessToken,
        fields: 'likes.summary(true),comments.summary(true),shares,attachments{media_type,media,url,title,type,subattachments}'
      }
    });

    const { likes, comments, shares, attachments } = response.data;

    // Extraction des URLs des médias
    let mediaUrls = [];
    if (attachments && attachments.data && attachments.data.length > 0) {
      attachments.data.forEach(attachment => {
        if (attachment.subattachments && attachment.subattachments.data) {
          // Cas des albums photos ou carrousels
          attachment.subattachments.data.forEach(subattachment => {
            if (subattachment.media && subattachment.media.image) {
              mediaUrls.push({
                url: subattachment.media.image.src,
                type: subattachment.media_type || attachment.type
              });
            }
          });
        } else if (attachment.media) {
          // Cas des posts simples avec une seule photo/vidéo
          if (attachment.media.image) {
            mediaUrls.push({
              url: attachment.media.image.src,
              type: attachment.media_type || attachment.type
            });
          } else if (attachment.url) {
            mediaUrls.push({
              url: attachment.url,
              type: attachment.media_type || attachment.type
            });
          }
        }
      });
    }

    return {
      likes: likes?.summary?.total_count || 0,
      comments: comments?.summary?.total_count || 0,
      shares: shares?.count || 0,
      postUrl: `https://facebook.com/${pageId}/posts/${postId}`,
      mediaUrls,
      isDeleted: false
    };
  } catch (error) {
    console.error('Erreur détaillée Facebook:', {
      message: error.message,
      response: error.response?.data,
      status: error.response?.status,
      postId,
      pageId
    });

    // Vérifier si le token est expiré
    if (isTokenExpired(error)) {
      await handleTokenExpiration(auth.currentUser.uid);
      return {
        likes: 0,
        comments: 0,
        shares: 0,
        postUrl: `https://facebook.com/${pageId}/posts/${postId}`,
        mediaUrls: [],
        isDeleted: false,
        error: 'Session Facebook expirée. Veuillez vous reconnecter.',
        tokenExpired: true
      };
    }

    // Vérifier si le post a été supprimé ou est inaccessible
    const isDeleted = error.response?.data?.error?.code === 100 || 
                     error.response?.status === 400;

    // Message d'erreur personnalisé
    let errorMessage = 'Erreur lors de la récupération des statistiques';
    if (isDeleted) {
      errorMessage = 'Ce post Facebook n\'est plus disponible ou a été supprimé';
    } else if (error.response?.data?.error?.message) {
      errorMessage = error.response.data.error.message;
    }

    // En cas d'erreur, retourner des statistiques par défaut
    return {
      likes: 0,
      comments: 0,
      shares: 0,
      postUrl: `https://facebook.com/${pageId}/posts/${postId}`,
      mediaUrls: [],
      isDeleted,
      error: errorMessage
    };
  }
}; 