import { getFunctions, httpsCallable } from 'firebase/functions';
import { showNotification } from '../../Notification/notificationService';
import { auth } from '../../../config/firebaseConfig';
import { getAuth, getIdToken } from 'firebase/auth';

export const publishToFacebookCloud = async (content, mediaFiles, userId) => {
  try {
    console.log("Tentative de publication Facebook via Cloud Function pour userId:", userId);
    
    // Vérifier que l'utilisateur est connecté
    const currentUser = auth.currentUser;
    if (!currentUser) {
      throw new Error("Utilisateur non authentifié");
    }

    // Attendre que l'état d'authentification soit initialisé
    await new Promise((resolve) => {
      const unsubscribe = auth.onAuthStateChanged((user) => {
        unsubscribe();
        resolve(user);
      });
    });

    // Obtenir un token d'authentification frais
    const idToken = await getIdToken(currentUser, true);
    console.log("Token d'authentification obtenu:", idToken.substring(0, 10) + "...");

    const functions = getFunctions();
    const publishToFacebookFunction = httpsCallable(functions, 'publishToFacebook', {
      timeout: 540000, // 9 minutes
    });

    const response = await publishToFacebookFunction({
      content,
      mediaFiles,
      userId: currentUser.uid,
    });

    console.log("Réponse de la Cloud Function:", response.data);

    if (response.data.success) {
      showNotification("Publication réussie sur Facebook", "success");
      return {
        success: true,
        id: response.data.id,
        pageId: response.data.pageId,
      };
    } else {
      throw new Error(response.data.error || "Erreur lors de la publication");
    }
  } catch (error) {
    console.error("Erreur détaillée lors de la publication Facebook via Cloud Function:", {
      message: error.message,
      code: error.code,
      details: error.details,
    });
    
    showNotification(`Erreur lors de la publication sur Facebook: ${error.message}`, "error");
    throw error;
  }
}; 