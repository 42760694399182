import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './config/style/theme'; // Import du thème personnalisé
import { AuthProvider } from './contexts/AuthContext';

import HomePage from './pages/HomePage/HomePage';
import SignUpPage from './pages/register/SignUpPage';
import LoginPage from './pages/register/LoginPage';
import CompanyFormPage from './pages/register/CompanyFormPage';
import DashboardPage from './pages/Dashboard/DashboardPage';
import ProfilePage from './pages/Dashboard/sections/Profile/ProfilePage';
import PostHistoryPage from './pages/Dashboard/sections/PostHistory/PostHistoryPage';
import NewPostPage from './pages/Dashboard/sections/NewPost/NewPostPage';
import ProtectedRoute from './components/ProtectedRoute';
import { loadFacebookSdk } from './services/socialNetworks/facebook/facebookSdk';
import { NotificationProvider } from './components/Notification/Notification'; // Import du NotificationProvider
import CalendarPage from './pages/Dashboard/sections/Calendar/CalendarPage';
import HomeAppPage from './pages/Dashboard/sections/Home/HomeAppPage';

function App() {
  useEffect(() => {
    loadFacebookSdk();
  }, []);

  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <NotificationProvider> {/* Envelopper l'application avec NotificationProvider */}
          <CssBaseline />
          <Router>
            <Routes>
              {/* Routes publiques */}
              <Route path="/" element={<HomePage />} />
              <Route path="/signup" element={<SignUpPage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/company-form" element={<CompanyFormPage />} />
              {/* Routes protégées du tableau de bord */}
              <Route path="/dashboard" element={<ProtectedRoute><DashboardPage /></ProtectedRoute>}>
                <Route index element={<Navigate to="home" replace />} /> {/* Redirection vers /dashboard/home */}
                <Route path="home" element={<HomeAppPage />} />
                <Route path="profile" element={<ProfilePage />} />
                <Route path="post-history" element={<PostHistoryPage />} />
                <Route path="new-post" element={<NewPostPage />} />
                <Route path="calendar" element={<CalendarPage />} />
              </Route>
            </Routes>
          </Router>
        </NotificationProvider>
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;
