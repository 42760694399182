import React, { useState, useEffect } from 'react';
import InstagramIcon from '@mui/icons-material/Instagram';
import SocialMediaAuthorizationCard from '../../../../../components/Card/SocialMediaAuthorizationCard';
import { useAuth } from '../../../../../contexts/AuthContext';
import { db } from '../../../../../config/firebaseConfig';
import { doc, setDoc, onSnapshot } from 'firebase/firestore';
import { Box } from '@mui/material';

/* global FB */

const InstagramAuth = () => {
  const { currentUser } = useAuth();
  const [isConnected, setIsConnected] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!currentUser?.uid) return;

    const unsubscribe = onSnapshot(
      doc(db, 'socialMediaAccounts', currentUser.uid),
      (doc) => {
        if (doc.exists()) {
          const data = doc.data();
          setIsConnected(data.instagram?.isConnected || false);
        }
      }
    );

    return () => unsubscribe();
  }, [currentUser]);

  const handleAuthorizeInstagram = () => {
    setLoading(true);
    try {
      if (typeof FB !== 'undefined') {
        FB.login(function(response) {
          if (response.authResponse) {
            console.log('Étape 1: Connexion Facebook réussie');
            const userAccessToken = response.authResponse.accessToken;

            // D'abord, vérifier les permissions
            FB.api('/me/permissions', { access_token: userAccessToken }, function(permResponse) {
              console.log('Permissions actuelles:', permResponse.data);
              
              // Récupérer les pages Facebook
              FB.api('/me/accounts', { access_token: userAccessToken }, function(pagesResponse) {
                if (pagesResponse && !pagesResponse.error) {
                  console.log('Étape 2: Pages Facebook récupérées', pagesResponse.data);
                  
                  if (!pagesResponse.data || pagesResponse.data.length === 0) {
                    console.error('Aucune page Facebook trouvée.');
                    console.log('Pour utiliser Instagram API, vous devez :');
                    console.log('1. Avoir une Page Facebook');
                    console.log('2. Être administrateur de cette Page');
                    console.log('3. Avoir un compte Instagram Professionnel lié à cette Page');
                    setLoading(false);
                    return;
                  }

                  const page = pagesResponse.data[0];
                  const pageId = page.id;
                  const pageAccessToken = page.access_token;
                  console.log('Page sélectionnée:', page.name);

                  // Vérifier si la page a un compte Instagram associé
                  FB.api(
                    `/${pageId}?fields=instagram_business_account`,
                    { access_token: pageAccessToken },
                    async function(instagramResponse) {
                      console.log('Réponse Instagram:', instagramResponse);
                      
                      if (instagramResponse && !instagramResponse.error && instagramResponse.instagram_business_account) {
                        console.log('Étape 3: Compte Instagram Business trouvé');
                        const instagramAccountId = instagramResponse.instagram_business_account.id;

                        try {
                          await setDoc(doc(db, 'socialMediaAccounts', currentUser.uid), {
                            instagram: {
                              isConnected: true,
                              pageId,
                              pageAccessToken,
                              instagramAccountId,
                              pageName: page.name,
                              connectedAt: new Date(),
                              userAccessToken
                            }
                          }, { merge: true });

                          console.log('Étape 4: Informations Instagram sauvegardées');
                        } catch (error) {
                          console.error('Erreur lors de la sauvegarde:', error);
                        }
                      } else {
                        console.error('Aucun compte Instagram Business trouvé.');
                        console.log('Pour lier un compte Instagram :');
                        console.log('1. Allez sur votre Page Facebook');
                        console.log('2. Dans les paramètres, section "Instagram"');
                        console.log('3. Cliquez sur "Connecter un compte"');
                      }
                      setLoading(false);
                    }
                  );
                } else {
                  console.error('Erreur lors de la récupération des pages:', pagesResponse?.error);
                  setLoading(false);
                }
              });
            });
          } else {
            console.log('Connexion Facebook annulée');
            setLoading(false);
          }
        }, {
          scope: 'instagram_basic,instagram_content_publish,pages_show_list,pages_read_engagement,pages_manage_posts,pages_manage_metadata'
        });
      } else {
        console.error('SDK Facebook non chargé');
        setLoading(false);
      }
    } catch (error) {
      console.error('Erreur lors de l\'autorisation Instagram:', error);
      setLoading(false);
    }
  };

  const handleDisconnect = async () => {
    setLoading(true);
    try {
      await setDoc(doc(db, 'socialMediaAccounts', currentUser.uid), {
        instagram: {
          isConnected: false,
          disconnectedAt: new Date()
        }
      }, { merge: true });
    } catch (error) {
      console.error('Erreur lors de la déconnexion:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <SocialMediaAuthorizationCard
      networkName="Instagram"
      networkLogo={<InstagramIcon sx={{ fontSize: 40, color: '#E4405F' }} />}
      description={
        <Box component="div">
          Pour publier sur Instagram, vous devez :
          <Box component="ul" sx={{ mt: 1 }}>
            <li>Avoir une <strong>Page Facebook</strong></li>
            <li>Avoir un <strong>compte Instagram Professionnel</strong></li>
            <li>Lier votre compte Instagram à votre Page Facebook</li>
          </Box>
        </Box>
      }
      isConnected={isConnected}
      isLoading={loading}
      onAuthorize={handleAuthorizeInstagram}
      onDisconnect={handleDisconnect}
    />
  );
};

export default InstagramAuth;
